import React from 'react';
import { graphql } from 'gatsby';
import PageLayout from '../components/PageLayout';
import { Colors } from '../utils/constants';
import Button from '../components/Button';
import styled, { ThemeProvider } from 'styled-components';

const CareerDetailWrapper = styled.div`
  width: 50%;
  padding: 20px;

  .jd-title{
    font-size: 20px;
    font-weight: bold;
    color: ${Colors.EYA_RED};
    padding-bottom: 10px;
  }
  
  ul{
    text-decoration: none;
    list-style: none;
  }
  
  .jd-desc{
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Career = (props) => {
  const career = props.data.mdx.frontmatter;
  const requirements = { title: 'Requirement', detail: career.careerDescription};
  const roles = { title: 'Roles', detail: career.careerRole};

  const renderListItems = (list) => {
    return list.map((item, index) => {
       return (
         <li key={index}>{item}</li>
       )
    });
  }

  const renderCareerInfo = ({title, detail}) => {
    return (
      <div>
        <div className="jd-title"> {title} </div>
        <ul className="jd-desc">
          {renderListItems(detail)}
        </ul>
      </div>
    );
  }

  return (
    <PageLayout title={career.name} className={props.className} >
      <div className="job-description">
        <CareerDetailWrapper>
          {renderCareerInfo(requirements)}
        </CareerDetailWrapper>
        <CareerDetailWrapper>
          {renderCareerInfo(roles)}
        </CareerDetailWrapper>
      </div>

      <a href={`mailto:submit@emergingyoungartists.org?subject=[${career.id}]`}
         target="_blank"
         rel="noopener noreferrer">
        <ThemeProvider theme={{style: "primary"}}>
            <Button>Join the team</Button>
        </ThemeProvider>
      </a>
    </PageLayout>
  )
}

export default styled(Career)`
.job-description{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
`;

export const query = graphql`
query($id: String) {
  mdx(frontmatter: { id: {regex: $id }}) {
    frontmatter {
        id
        name
        careerDescription
        careerRole
      }
    }
  }
`;
